<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="d-flex flex-column" style="height: 100%" v-if="shift">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Edit Shift</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0 align-center d-flex flex-column flex-grow overflow-auto"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Shift title* </label>
              <v-text-field
                v-model="title"
                label="Example: Morning time shift or Shift 1"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                rounded
                outlined
                dense
                autocomplete="off"
                :error-messages="hourMessage"
                @input="hourMessage = null"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Shift Date/Time* </label>
              <v-row :class="$vuetify.breakpoint.xs ? 'w-100' : ''">
                <v-col :class="$vuetify.breakpoint.xs ? 'pl-0' : ''">
                  <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :top="$vuetify.breakpoint.xs"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeStr"
                        label="MM/DD/YYYY"
                        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                        solo
                        flat
                        rounded
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        autocomplete="off"
                        :error-messages="timeMessage"
                        @input="timeMessage = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      scrollable
                      class="date-picker"
                      :min="minDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="date_picker = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="onSaveDate(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col :class="$vuetify.breakpoint.xs ? 'pr-0' : ''">
                  <vue-timepicker format="hh:mm a" v-model="startTime" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label">Shift Hours* </label>
              <v-text-field
                v-model="hours"
                label="Example: 4 or 3.5"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                rounded
                outlined
                dense
                type="number"
                autocomplete="off"
                :error-messages="hourMessage"
                @input="hourMessage = null"
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-baseline"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <label class="title-label"
                >Shift Capacity*
                <tip-icon>
                  Enter the number of volunteer spots available.
                </tip-icon>
              </label>
              <v-text-field
                v-model="capacity"
                label="Example: 30"
                :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
                solo
                flat
                rounded
                outlined
                dense
                type="number"
                autocomplete="off"
                :error-messages="capacityMessage"
                @input="capacityMessage = null"
              />
            </v-col>
          </v-row>
        </v-container>
        <div style="width: 100%">
          <small>*indicates required field</small>
        </div>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="onSaveShift"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    TipIcon,
    VueTimepicker,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    shift: Object,
    onCloseDialog: {
      type: Function,
    },
    onSavedShift: {
      type: Function,
    },
  },
  data() {
    return {
      title: "",
      timeStr: "",
      hours: "",
      capacity: "",
      startDate: null,
      date_picker: false,
      startTime: null,
      timeMessage: null,
      hourMessage: null,
      capacityMessage: null,
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      minDate: "2021-01-01",
    };
  },
  methods: {
    ...mapActions({
      editShift: "need/editShift",
      getTimeZoneName: "usersetting/getTimeZoneName",
    }),
    onClose() {
      this.timeStr = "";
      this.hours = null;
      this.capacity = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
      this.hoursMessage = null;
      this.zipMessage = null;
      this.interestsMessage = null;
    },
    async onSaveDate() {
      if (!this.startDate) return;
      const timezone = await this.getTimeZoneName();
      var date = new Date(this.startDate + " " + timezone);
      this.timeStr = dateFormat(date, "mm/dd/yyyy");
      this.date_picker = false;
    },
    onSaveShift() {
      if (!this.timeStr)
        this.timeMessage = "The Date fields are required to add a shift.";
      if (!this.startTime)
        this.timeMessage = "The time fields are required to add a shift.";
      if (!this.hours)
        this.hourMessage = "The hour fields are required to add a shift.";
      if (!this.capacity)
        this.capacityMessage =
          "The capacity fields are required to add a shift.";
      if (!this.timeStr || !this.hours || !this.capacity) {
        return;
      }
      this.error = null;
      this.loading = true;
      this.editShift({
        _id: this.shift._id,
        title: this.title,
        start_date: `${this.timeStr} ${this.startTime.hh}:${this.startTime.mm} ${this.startTime.a}`,
        hours: this.hours,
        capacity: this.capacity,
      })
        .then((res) => {
          this.loading = false;
          this.onSavedShift(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    this.minDate = dateFormat(new Date(), "yyyy-mm-dd");
    if (this.shift) {
      console.log(this.shift.start_date);
      var convertedDate = this.convertTZ(this.shift.start_date, this.timezone);
      this.timeStr = dateFormat(convertedDate, "mm/dd/yyyy");
      this.startTime = {
        hh: dateFormat(convertedDate, "hh"),
        mm: dateFormat(convertedDate, "MM"),
        a: dateFormat(convertedDate, "tt"),
      };
      console.log(this.startTime);
      this.hours = this.shift.hours;
      this.capacity = this.shift.capacity;
    }
  },
  watch: {
    shift(newValue) {
      var convertedDate = this.convertTZ(newValue.start_date, this.timezone);
      this.timeStr = dateFormat(
        this.convertTZ(newValue.start_date, this.timezone),
        "mm/dd/yyyy"
      );
      this.startTime = {
        hh: dateFormat(convertedDate, "hh"),
        mm: dateFormat(convertedDate, "MM"),
        a: dateFormat(convertedDate, "tt"),
      };
      this.hours = newValue.hours;
      this.capacity = newValue.capacity;
      this.title = newValue.title;
    },
    dialog(newValue) {
      if (newValue) {
        if (this.shift) {
          var convertedDate = this.convertTZ(
            this.shift.start_date,
            this.timezone
          );
          this.timeStr = dateFormat(convertedDate, "mm/dd/yyyy");
          this.startTime = {
            hh: dateFormat(convertedDate, "hh"),
            mm: dateFormat(convertedDate, "MM"),
            a: dateFormat(convertedDate, "tt"),
          };
          console.log(this.startTime);
          this.hours = this.shift.hours;
          this.capacity = this.shift.capacity;
        }
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div >>> .v-dialog {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.title-label {
  width: 200px;
}

.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div >>> ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
div >>> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div >>> ::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div >>> ::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.date-picker {
  border-radius: 4px 0 0 4px;
}
.time-picker {
  width: 100%;
}
.time-picker >>> input.display-time {
  height: 40px !important;
  width: 100%;
  border-radius: 20px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker >>> input.display-time:hover {
  border-color: currentColor;
}
.time-picker >>> input.display-time:focus-visible {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker >>> .clear-btn {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
