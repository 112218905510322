<template>
  <div class="mt-5 mx-5" style="width: 100%; min-width: 300px">
    <v-skeleton-loader type="article, article" class="mt-5" v-if="loading" />
    <v-alert
      type="error"
      v-if="need && !schedules.length && !loadingShifts"
      class="mt-0 mb-5"
    >
      The information registered in the need is presented incomplete.
      <div v-if="profileType == 'Agency' && profile._id == need.agency">
        You need to complete the "shift" field so that the volunteers can start
        their volunteering in your need.
      </div>
    </v-alert>
    <v-card v-if="need" class="mt-0 mb-5">
      <v-card-title
        class="mx-2 d-flex align-start"
        :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
      >
        <div v-if="$vuetify.breakpoint.smAndUp">
          <v-img
            :src="require('@/assets/svg/needs.svg')"
            :width="$vuetify.breakpoint.xs ? 40 : 80"
            :height="$vuetify.breakpoint.xs ? 40 : 80"
            contain
          />
        </div>
        <div
          class="d-flex flex-column flex-grow"
          :style="
            $vuetify.breakpoint.xs ? '' : 'width: 100px; margin-left: 20px'
          "
        >
          <div
            class="app-medium-font break-word"
            :class="
              $vuetify.breakpoint.xs
                ? 'font-16 d-flex align-center'
                : 'font-20 pr-5'
            "
          >
            <div v-if="$vuetify.breakpoint.xs" class="mr-3">
              <v-img
                :src="require('@/assets/svg/needs.svg')"
                :width="40"
                :height="40"
                contain
              />
            </div>
            {{ need.title }}
          </div>
          <div
            class="app-medium-font"
            :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
          >
            <div class="url-button" @click="goToAgencyProfile">
              {{ need.agency_name }}
            </div>
          </div>
          <span
            class="app-regular-font primary-color"
            :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
          >
            {{ need.address ? need.address + ", " : " " }}{{ need.city }},
            {{ need.state }} {{ need.zip }}
          </span>
          <div class="d-flex flex-row flex-wrap">
            <site-icon
              :name="icon"
              type="Interest"
              v-for="(icon, key) in need.interests"
              :key="key"
              class="mr-3 mt-3"
              :size="$vuetify.breakpoint.xs ? '30' : '40'"
            />
          </div>
        </div>
        <div class="flex-shrink justify-end d-flex flex-column">
          <span
            @click="onResponse"
            class="url-button font-normal"
            :class="$vuetify.breakpoint.xs ? 'font-12 mt-3' : 'font-14 mb-5'"
            v-if="need.agency == profile._id"
          >
            Volunteer List
          </span>
          <span
            @click="goToDiary"
            class="url-button font-normal"
            :class="$vuetify.breakpoint.xs ? 'font-12 mt-3' : 'font-14 mb-5'"
            v-else-if="profileType == 'Volunteer'"
          >
            View work diary
          </span>
          <div class="d-flex flex-shrink justify-end">
            <v-btn color="#B5E539" small @click="startWork" v-if="workable">
              Work
            </v-btn>
            <v-tooltip bottom v-if="need.agency == profile._id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="onEditNeed"
                >
                  <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              Edit Need
            </v-tooltip>
            <v-tooltip bottom v-if="need.agency == profile._id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDeleteNeed"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete Need
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onShare"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                    >mdi-share</v-icon
                  >
                </v-btn>
              </template>
              Share
            </v-tooltip>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="dark-font-color app-bold-font pb-1">
          Description
        </div>
        <span class="app-regular-font" v-linkified>
          {{ need.description }}
        </span>
      </v-card-text>
      <v-card-text>
        <div
          class="pb-2 d-flex flex-row app-bold-font dark-font-color"
          :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
        >
          <v-icon size="16" color="#6038f7" class="mr-1">mdi-map-marker</v-icon
          >{{ need.address ? need.address + ", " : " " }}{{ need.city }},
          {{ need.state }} {{ need.zip }}
        </div>
        <GmapMap
          :center="center"
          :zoom="13"
          map-type-id="terrain"
          class="map"
          v-if="center"
        >
          <GmapMarker :position="center" />
        </GmapMap>
      </v-card-text>
    </v-card>
    <v-card v-if="need" class="mb-5">
      <v-card-title
        class="d-flex flex-row"
        style="justify-content: space-between"
      >
        <span>
          Shift
        </span>
        <v-btn
          color="#B5E539"
          small
          @click="onAddShift"
          v-if="need.agency == profile._id"
        >
          Add Shift
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="schedules"
          @dblclick:row="goToShift"
        >
          <template v-slot:[`item.title`]="{ item }">
            {{
              item.title ? item.title : `Shift ${schedules.indexOf(item) + 1}`
            }}
          </template>
          <template v-slot:[`item.begins`]="{ item }">
            {{ formatDate(item.start_date) }}
          </template>
          <template v-slot:[`item.hours`]="{ item }">
            {{ item.hours }} hrs
          </template>
          <template v-slot:[`item.capacity`]="{ item }">
            {{
              item.responses
                ? item.capacity -
                  item.responses.filter((r) => r.status != "Rejected").length
                : item.capacity
            }}
            of
            {{ item.capacity }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ getResponseStatus(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div style="width: 65px" v-if="need.agency == profile._id">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                    small
                    v-bind="attrs"
                    v-on="on"
                    v-if="need.agency == profile._id"
                    @click.stop="onEditShift(item)"
                  >
                    <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                Edit Shift
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                    small
                    class="ml-2"
                    @click.stop="onDeleteShift(item)"
                    v-bind="attrs"
                    v-on="on"
                    v-if="need.agency == profile._id"
                  >
                    <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                Delete Shift
              </v-tooltip>
            </div>
            <div
              v-if="profileType == 'Volunteer'"
              :style="checkConfirmedResponse(item) ? 'width: 157px;' : ''"
            >
              <v-btn
                color="#b5e539"
                x-small
                @click="onRepondShift(item)"
                v-if="checkMyResponse(item)"
                width="72"
                :loading="item.loading"
                class="mr-3 my-1"
              >
                View
              </v-btn>
              <v-btn
                color="#b5e539"
                x-small
                @click="startWork(item)"
                v-if="checkConfirmedResponse(item)"
                width="72"
                :loading="item.loading"
                class="mt-1 my-1"
              >
                Work
              </v-btn>
              <v-btn
                color="#b5e539"
                x-small
                v-else-if="item.responses.length == item.capacity"
                width="72"
                disabled
              >
                No spots
              </v-btn>

              <v-btn
                color="#b5e539"
                x-small
                @click="onRepondShift(item)"
                v-else-if="checkSubmittedResponse(item)"
                width="72"
              >
                Respond
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card
      v-if="
        need &&
          (need.min_age != 0 ||
            need.max_age != 120 ||
            (need.family_friendly && need.family_friendly != 'N/A') ||
            (need.outdoors && need.outdoors != 'N/A') ||
            (need.wheelchair_accessible &&
              need.wheelchair_accessible != 'N/A') ||
            (need.attributes && need.attributes.length != 0))
      "
      class="mb-5"
    >
      <v-card-title>Additional Details</v-card-title>
      <v-card-text
        v-if="need.min_age != 0 || (need.max_age && need.max_age != 120)"
        class="mb-0"
      >
        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
        <span v-if="need.min_age != 0 && need.max_age != 120 && need.max_age">
          {{ need.min_age }} to {{ need.max_age }} years old
        </span>
        <span v-else-if="need.min_age"> {{ need.min_age }} and up </span>
        <span v-else-if="need.max_age && need.max_age != 120">
          {{ need.max_age }} and younger
        </span>
      </v-card-text>
      <v-card-text
        v-if="need.family_friendly && need.family_friendly != 'N/A'"
        class="pt-0"
      >
        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
        <span v-if="need.family_friendly == 'Yes'">
          Is Family Friendly
        </span>
        <span v-else> Is Not Family Friendly </span>
      </v-card-text>
      <v-card-text v-if="need.outdoors && need.outdoors != 'N/A'" class="pt-0">
        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
        <span v-if="need.outdoors == 'Yes'">
          Is Outdoors
        </span>
        <span v-else> Is Not Outdoors </span>
      </v-card-text>
      <v-card-text
        v-if="need.wheelchair_accessible && need.wheelchair_accessible != 'N/A'"
        class="pt-0"
      >
        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
        <span v-if="need.wheelchair_accessible == 'Yes'">
          Is Outdoors
        </span>
        <span v-else> Is Not Outdoors </span>
      </v-card-text>
      <v-card-text
        v-if="need.attributes && need.attributes.length != 0"
        class="pt-0"
      >
        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
        <span>
          {{ need.attributes.join(", ") }}
        </span>
      </v-card-text>
    </v-card>
    <v-card v-if="!need && error">
      <v-card-title class="py-15 justify-center">
        {{ error }}
      </v-card-title>
    </v-card>
    <agency-edit-need
      :item="need"
      :dialog="editDialog"
      :onCloseDialog="onCloseEditDialog"
    />
    <confirm-delete-need-dialog
      :deleteDialog="deleteDialog"
      :need="need"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onDeletedNeed"
    />
    <confirm-delete-shift-dialog
      :deleteDialog="deleteShiftDialog"
      :shift="selectedShift"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onShiftDeleted"
    />
    <create-shift
      v-if="need"
      :dialog="createShiftDialog"
      :need_id="need._id"
      :onCloseDialog="onCloseCreateShiftDialog"
      :onCreatedShift="onCreatedShift"
    />
    <edit-shift
      :dialog="editShiftDialog"
      :shift="selectedShift"
      :onCloseDialog="onCloseEditShiftDialog"
      :onSavedShift="onSavedShift"
    />
    <response-shift
      :dialog="responseDialog"
      :shift="selectedShift"
      :need="need"
      :onCloseDialog="onCloseResponseDialog"
      :onResponse="onRespondedShift"
      :onCancelled="onCancelledShift"
    />
    <volunteer-time-tracker
      :dialog="trackerDialog"
      :onCloseDialog="onCloseTrackerDialog"
      :need_id="need._id"
      v-if="profileType == 'Volunteer' && need"
    />
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDeleteNeedDialog from "@/components/common/ConfirmDeleteNeedDialog.vue";
import AgencyEditNeed from "./create/AgencyEditNeed.vue";
import CreateShift from "./shift/CreateShift.vue";
import dateFormat from "dateformat";
import ConfirmDeleteShiftDialog from "@/components/common/ConfirmDeleteShiftDialog.vue";
import EditShift from "./shift/EditShift.vue";
import ResponseShift from "../../volunteer/response/ResponseShift.vue";
import VolunteerTimeTracker from "../../volunteer/response/VolunteerTimeTracker.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: {
    ConfirmDeleteNeedDialog,
    AgencyEditNeed,
    CreateShift,
    ConfirmDeleteShiftDialog,
    EditShift,
    ResponseShift,
    VolunteerTimeTracker,
    SiteIcon,
  },
  data() {
    return {
      loading: false,
      loadingShifts: false,
      need: null,
      center: null,
      schedules: [],
      headers: [
        { text: "Title", value: "title" },
        { text: "Begins", value: "begins" },
        { text: "Duration", value: "hours" },
        { text: "Open spots", value: "capacity" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      deleteDialog: false,
      snackbar: false,
      snackMessage: null,
      editDialog: false,
      selectedShift: null,
      createShiftDialog: false,
      deleteShiftDialog: false,
      editShiftDialog: false,
      responseDialog: false,
      workable: false,
      trackerDialog: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone",
    }),
  },
  methods: {
    ...mapActions("need", {
      getNeedDetails: "getNeedDetails",
      getLocation: "getLocation",
      fetchAllShifts: "fetchAllShifts",
      deleteShift: "deleteShift",
      cancelResponse: "cancelResponse",
    }),
    getLocationFromAddress() {
      var address = `${this.need.address}${
        this.need.address2 ? " " + this.need.address2 : ""
      }, ${this.need.city}, ${this.need.state} ${this.need.zip}`;
      this.getLocation(address).then((res) => {
        this.center = res;
      });
    },
    getAllShifts() {
      this.loadingShifts = true;
      this.fetchAllShifts({ need_id: this.$route.query._id })
        .then((res) => {
          this.loadingShifts = false;
          this.schedules = res;
          this.workable = false;
          for (var i = 0; i < this.schedules.length; i++) {
            for (var j = 0; j < this.schedules[i].responses.length; j++) {
              if (
                this.schedules[i].responses[j].user_id == this.profile._id &&
                this.schedules[i].responses[j].status == "Confirmed"
              )
                this.workable = true;
              break;
            }
            if (this.workable) break;
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.loadingShifts = false;
        });
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    ///Agency
    onEditNeed() {
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseCreateShiftDialog() {
      this.createShiftDialog = false;
    },
    onAddShift() {
      this.createShiftDialog = true;
    },
    onCreatedShift(shift) {
      this.createShiftDialog = false;
      this.schedules.push(shift);
    },
    onDeleteNeed(need) {
      this.selected = need;
      this.deleteDialog = true;
    },
    onDeletedNeed() {
      this.deleteDialog = false;
      this.$router.back();
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
      this.deleteShiftDialog = false;
    },
    onEditShift(shift) {
      this.selectedShift = shift;
      this.editShiftDialog = true;
    },
    onDeleteShift(shift) {
      this.selectedShift = shift;
      this.deleteShiftDialog = true;
    },
    onCloseEditShiftDialog() {
      this.editShiftDialog = false;
    },
    onSavedShift(shift) {
      this.editShiftDialog = false;
      this.schedules = this.schedules.map((s) => {
        if (s._id != shift._id) {
          return s;
        } else {
          return shift;
        }
      });
    },
    onShiftDeleted(shift) {
      this.schedules = this.schedules.filter((s) => s._id != shift._id);
      this.deleteShiftDialog = false;
    },
    goToShift(event, { item }) {
      if (this.profile._id == this.need.agency) {
        this.$router.push({
          name: "agency-responses-feeds",
          query: { _id: this.need._id, selected_shift: item._id },
        });
      }
    },
    onResponse() {
      this.$router.push({
        name: "agency-responses-feeds",
        query: { _id: this.need._id },
      });
    },
    ///Volunteer
    onRepondShift(shift) {
      this.selectedShift = shift;
      this.responseDialog = true;
    },
    onRespondedShift(response) {
      this.responseDialog = false;
      this.schedules = this.schedules.map((shift) => {
        if (shift._id == response.shift_id) {
          shift.responses = shift.responses.filter(
            (r) => r._id != response._id
          );
          shift.responses.push(response);
        }
        return shift;
      });
    },
    onCancelledShift(response) {
      this.responseDialog = false;
      this.schedules = this.schedules.map((shift) => {
        if (shift._id == response.shift_id) {
          shift.responses = shift.responses.filter(
            (r) => r._id != response._id
          );
        }
        return shift;
      });
    },
    onCloseResponseDialog() {
      this.responseDialog = false;
    },
    checkMyResponse(shift) {
      for (var i = 0; i < shift.responses.length; i++) {
        if (shift.responses[i].user_id == this.profile._id) return true;
      }
      return false;
    },
    checkConfirmedResponse(shift) {
      for (var i = 0; i < shift.responses.length; i++) {
        if (
          shift.responses[i].user_id == this.profile._id &&
          shift.responses[i].status == "Confirmed"
        )
          return true;
      }
      return false;
    },
    checkSubmittedResponse(shift) {
      for (var i = 0; i < shift.responses.length; i++) {
        if (shift.responses[i].user_id == this.profile._id) return false;
      }
      return true;
    },
    getResponseStatus(shift) {
      for (var i = 0; i < shift.responses.length; i++) {
        if (
          shift.responses[i].user_id == this.profile._id &&
          shift.responses[i].status
        )
          return shift.responses[i].status;
      }
      return "";
    },
    startWork() {
      this.trackerDialog = true;
    },
    onCloseTrackerDialog() {
      this.trackerDialog = false;
    },
    onCancelRespond(shift) {
      this.schedules = this.schedules.map((s) =>
        s._id == shift._id ? { ...s, loading: true } : s
      );
      this.cancelResponse({ _id: shift._id })
        .then((res) => {
          shift.responses = shift.responses.filter((r) => r._id != res._id);
          this.schedules = this.schedules.map((s) =>
            s._id == shift._id ? shift : s
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onShare() {
      this.copyToClipboard(
        process.env.VUE_APP_WEB_URL + "/needs/details?_id=" + this.need._id
      );
      this.snackbar = true;
      this.snackMessage = "copied share url";
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    goToDiary() {
      this.$router.push({
        name: "volunteer-diary",
        query: {
          need_id: this.need._id,
        },
      });
    },
    goToAgencyProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.need.agency },
      });
    },
  },
  mounted() {
    if (!this.$route.query._id) {
      this.$router.push({ name: "agency-need-feeds" });
      return;
    }
    if (this.profileType == "Volunteer") {
      this.headers = [
        { text: "Title", value: "title" },
        { text: "Begins", value: "begins" },
        { text: "Duration", value: "hours" },
        { text: "Open spots", value: "capacity" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    }
    this.loading = true;
    this.getNeedDetails({ _id: this.$route.query._id })
      .then((need) => {
        this.loading = false;
        this.need = need;
        this.getLocationFromAddress();
        this.getAllShifts();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error.response.data.message);
          if (error.response.data.message == "Need not found") {
            this.error = "Deleted or invalid need";
          }
        }
      });
  },
  watch: {
    profileType(newValue) {
      if (newValue == "Volunteer") {
        this.headers = [
          { text: "Title", value: "title" },
          { text: "Begins", value: "begins" },
          { text: "Duration", value: "hours" },
          { text: "Open spots", value: "capacity" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
    },
  },
};
</script>
<style scoped>
.map {
  width: 100%;
  height: 300px;
}
</style>
